<template>
  <div class="bio" :class="device()">
    <div>
      <div class="img-container">
        <img
          :src="require('@/assets/img/logo.png')"
          alt="Face of Eric."
        />
      </div>

      <div>
        <h2>
          Eric VDE
        </h2>

        <p class="body">
          Van kindsbeen af kon Eric zich uren bezighouden met tekenen, knutselen
          en schilderen. Op de middelbare school volgde hij de opleiding grafische
          vormgeving - publiciteit, waar hij leerde zeefdrukken en etsen. Tijdens
          de hogere schooljaren kon Eric zijn talenten verder ontwikkelen in de
          Stedelijke Kunstacademie van Turnhout. Eric vertrok in 1993 met zijn
          gezin naar Zuid-Afrika, waar hij het schilderen op een laag pitje moest
          zetten. Op 21 juli 1999 kwam Eric terug naar België en werd zijn interesse
          voor moderne schilderkunst weer aangescherpt. Hij vond zijn inspiratie
          vooral in de Popart, een populaire cultuur met beelden uit de reclame-
          en televisiewereld, strips, pin-ups en popmuziek. Zijn grote voorbeelden
          Robert Rauschenberg, Tom Wesselmann, Roy Lichtenstein, Andy Warhole en
          de felle kleuren die hij in Afrika leerde kennen vormen de basis van de
          werken die Eric vandaag maakt.
        </p>

        <p>
          <i>
            "Uiteindelijk is iedereen zelf <br />
            het ware kunstwerk in zijn eigen beleven."
          </i>
        </p>

        <a href="mailto:eric.vandeneynden@gmail.com">
          Contacteer mij
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import deviceMixin from '@/mixins/deviceMixin'

export default {
  name: 'Bio',
  mixins: [deviceMixin],
}
</script>

<style lang="scss" scoped>
.bio {
  padding: 6rem 3rem 3rem 3rem;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &.mobile {
    padding: 6rem 1.5rem 7.5rem 1.5rem;

    h2 {
      font-size: 1.5rem;
      margin-top: 2rem;
    }
  }

  &.mobile > div {
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;
    }

    a {
      margin: 2rem auto;
    }
  }

  > div {
    display: flex;
    gap: 3rem;

    .img-container {
      width: 10rem;
      height: 10rem;
      padding: 2rem;
      flex-shrink: 0;
      border-radius: 20rem;
      background-color: #fff;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      margin-top: 1rem;
    }
  
    p {
      font-weight: bold;
      margin-top: 1rem;
  
      &.body {
        width: 100%;
        margin-bottom: 3rem;
  
        @media (min-width: 1200px) {
          width: 500px;
        }
  
        @media (min-width: 1400px) {
          width: 700px;
        }
      }
    }

    a {
      text-decoration: none;
      color: inherit;
      background: rgb(224, 224, 224);
      padding: 1rem 1.5rem;
      border-radius: 200rem;
      display: inline-block;
      transition: all 150ms ease-in-out;
      margin-top: 3rem;
      font-weight: bold;

      &:hover {
        background: rgb(199, 199, 199);
      }
    }
  }
}
</style>
